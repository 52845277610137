<template>
  <div class="forgot-password">
    <logo />
    <h1 class="mb-8 text-xl leading-8.5">
      {{ $t('forgot-password-view.forgot-password') }}
    </h1>
    <well class="max-w-xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="form" @submit.prevent="handleSubmit(send)">
          <p v-if="apiError" class="error-msg">
            {{ $t('forgot-password-view.email-error') }}
          </p>
          <p class="text-gray-darkest mb-4">
            {{ $t('forgot-password-view.info-message') }}
          </p>
          <ValidationProvider v-slot="{ errors }" rules="required|email">
            <text-input
              v-model="email"
              :placeholder="$t('forgot-password-view.email')"
              :error="apiError"
              :error-message="errors[0]"
              class="w-full mb-6"
            />
          </ValidationProvider>
          <btn :disabled="loading" type="submit" class="w-full">
            <span v-if="!loading">{{ $t('forgot-password-view.send') }}</span>
            <IconSpinner v-else />
          </btn>
        </form>
      </ValidationObserver>
    </well>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapActions } from 'vuex';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import { TextInput } from '@/components/Inputs';
import Btn from '@/components/Button/Button.vue';
import Well from '@/components/Well/Well.vue';
import Logo from '@/components/Logo/Logo';
import { emailRegex } from '@/util/form';
export default {
  components: {
    IconSpinner,
    TextInput,
    Btn,
    Well,
    ValidationProvider,
    ValidationObserver,
    Logo,
  },
  data() {
    return {
      email: '',
      apiError: false,
      loading: false,
    };
  },
  created() {
    extend('email', {
      validate(value) {
        return value.match(emailRegex);
      },
      message: this.$t('forgot-password-view.login-error-email'),
    });

    extend('required', {
      ...required,
      message: this.$t('forgot-password-view.login-error-empty'),
    });
  },
  methods: {
    ...mapActions('user', ['forgotPassword']),
    async send() {
      this.loading = true;
      try {
        await this.forgotPassword({
          email: this.email,
        });
        this.loading = false;
        this.$toast.success(this.$t('forgot-password-view.toast'));
      } catch (err) {
        this.loading = false;
        this.apiError = true;
      }
    },
  },
};
</script>
<style scoped>
.forgot-password {
  @apply p-4 flex flex-col items-center justify-center h-screen text-white bg-cover;
  background-image: url('../assets/background.png');
}
.form {
  @apply relative;
}
.error-msg {
  @apply text-center text-red absolute -mt-9 top-0 w-full;
}
</style>
